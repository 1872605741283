.consent-paragraph {
    height: 400px;
    width: 474px;
    background-color: #FFFFFF;
    padding: 10px;
    border-radius: 12px;
    overflow: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    scrollbar-width: thin;
    scrollbar-color: #94A3B8 ; 
}

.consent-paragraph::-webkit-scrollbar {
    width: 14px;
}

.consent-paragraph::-webkit-scrollbar-thumb {
    background-color: #94A3B8;
    border-radius: 8px;
    height: 2px;
    margin-left: 20px;
}

.consent-paragraph::-webkit-scrollbar-thumb:hover {
    background-color: #888;
}
